import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Cloud from "@material-ui/icons/Cloud";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Eco from "@material-ui/icons/Eco";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>About Telchak</h2>
          <h5 className={classes.description}>
            Private Blockchain, compatible with existing technology. Telchak
            provides the tech for your digital transactions, delivering safe
            and fast value to your business.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Cloud"
              description="The Telchak Blockchain runs in the Cloud, taking advantage of the scalability, availability, security and energy-saving features."
              icon={Cloud}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Verified Users"
              description="Be in control of your Blockchain, and select which users are allowed to use the network."
              icon={VerifiedUser}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Mining"
              description="Remove the mining from the equation, run a green Blockchain and reduce the costs of keeping the Blockchain running."
              icon={Eco}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
